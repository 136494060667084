export default {
    locales: {
        en: "English",
    },
    acttrader:'ActTrader',
    compairacc: 'Compare Accounts',
    ecnacc: 'ECN ACCOUNT',
    openacc: 'Open Account',
    crypto: 'Cyrptocurrencies',
    stock: 'Stocks',
    forex: 'Forex',
    indices: 'Indices',
    commodities: 'Commodities',
    negativebalance: 'Negative Balance Protection',
    minorderdest: 'Min.order Distance',
    stopout: 'stop-out',
    margincall: 'Margin Call',
    yes: 'Yes',
    no: 'No',
    lot: 'lot',
    usd: 'USD',
    eur: 'EUR',
    gbp: 'GBP',
    aud: 'AUD',
    basecurrency: 'Base currencies',
    upto: 'up to',
    mindeposit: 'Min. Deposit',
    account: 'Account',
    zulu:`zulu`,
    plus: 'plus',
    max: 'max',
    min: 'min',
    leverage:'Leverage',
    per: 'per',
    commission: 'Commission',
    minpip: 'min. pip',
    spreads: 'Spreads',
    spread: 'Spread',
    ecn: 'ECN',
    keyinfo: 'Key Info Docs',
    mifid: 'MIFID Legislation',
    privacypolicy: 'Privacy Policy',
    contact: 'Contact 24/5',
    webtrader: 'Web-Trader',
    login: 'Login',
    joinNow: 'Join Now',
    aboutAAAfx: {
        name: 'About AAAFx',
        submenu: {
            why: {
                title: 'Why AAAFx',
                tagline: 'Know who we are'
            },
            safety: {
                title: 'Safety of Funds',
                tagline: 'We have got your back'
            },
            servers: {
                title: 'Our Servers',
                tagline: 'Best systems for best results'
            },
            regulations: {
                title: 'Regulations and Licenses',
                tagline: 'Reliable and Secure Trading'
            },
            legalDocs: {
                title: 'Legal Documents',
                tagline: 'Read all Legal Documents'
            },
            careers: {
                title: 'Careers',
                tagline: 'Join our Team'
            },
            contactUs: {
                title: 'Contact Us',
                tagline: `We'd love to hear from you`
            },
        }
    },
    trading: {
        name: 'Trading',
        submenu: {
            products: {
                title: 'Trading Products',
                tagline: 'Trading Instruments we offer'
            },
            accountTypes: {
                title: 'Accounts Types',
                tagline: 'Choose what suits your requirements'
            },
            tradingConditions: {
                title: 'Trading Conditions',
                tagline: 'Everything to suit your best interests'
            },
            depWithdrawal: {
                title: 'Deposits & Withdrawals',
                tagline: 'Hassle Free money management'
            },
        }
    },
    platforms: {
        name: 'Platforms',
        submenu: {
            mt4: {
                title: 'MT4',
                tagline: 'Most popular trading platform'
            },
            mt5: {
                title: 'MT5',
                tagline: 'Advanced and Multi functional'
            },
            act: {
                name:'ACT',
                title: 'ActTrader',
                tagline: 'Easy to use and most convenient'
            },
            comparison: {
                title: 'Comparison of Platforms',
                tagline: 'Make an informed decision'
            },
        }
    },
    promotions: {
        name: 'Promotions',
        submenu: {
            bonus: {
                title: 'Deposit Bonus',
                tagline: 'Double your margins at no cost'
            },
            refer: {
                title: 'Refer & Earn',
                tagline: 'Invite your friends and share the benefits'
            },
            vps: {
                title: 'Free VPS',
                tagline: 'Avail free high speed trading'
            },
        }
    },
    tools: {
        name: 'Tools & Resources',
        submenu: {
            zulu: {
                title: 'ZuluTrade',
                tagline: 'Join the largest social trading network'
            },
            calculators: {
                title: 'Trading Calculators',
                tagline: 'Calculations made easy for you'
            },
            ecoCalendar: {
                title: 'Economic Calendar',
                tagline: 'Stay updated and trade wisely'
            },
            partnerships: {
                title: 'Partnerships',
                tagline: 'Partner with us and grow'
            },
            faq: {
                title: 'FAQs',
                tagline: 'Get answers to common questions'
            },
        }
    },
    legalinfo: {
        name: 'Legal Information',
        para1: `Triple A Experts Investment Services Single Member SA is EU authorized and regulated by the Hellenic Capital Market Commission (id:2/540/17.2.2010) with registered address at Triple A Experts Investment Services S.A., 14 Akti Kondyli street, 18545 Piraeus, Greece.`,
        para2: `Sikhula Venture Capital (Pty) Ltd. is authorized and regulated by the South African Financial Sector Conduct Authority (FSCA No. 2017/315029/07).`,
        para3: `www.aaafx.com is operated by Triple A Experts Investment Services Single Member SA`,
        para4: `www.aaafx.com is operated by Triple A Experts Investment Services Single Member SA.`,
    },
    riskdisc: {
        name: 'Risk Disclosure',
        para:`CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage. 66% of retail investor accounts lose money when trading CFDs with AAAFx. You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money. Details can be found`,
        link: `here.`,
    },
    accounttypepage:{
        title: 'Account Types',
        tagline1: `Choose the account that suits your trading needs! We have exciting offerings for`,
        tagline2: `every trader, in a highly simplified manner.`,
        para1:{
            desc1: `There are three different account types, that match the requirements of a novice trader, a professional trader, or an institutional trader. Some of the common exciting features assured in all the accounts are:`,
            point1: `EU Regulated Broker`,
            point2: `Ultra -Thin spreads`,
            point3: `All trading strategies allowed`,
            point4: `No Min. Order distance-place Orders as you like`,
            point5: `Negative Balance Protection`,
            point6: `Micro Lots Trading`,
            desc2: `Without any discrimination we offer institutional grade spreads and liquidity to all traders alike.`
        },
        accdesc: {
            title: 'Account Description',
            point1:{
                title: 'ECN Account',
                desc: `Enjoy raw ECN spreads and lightning-fast trade execution at very low cost. Low commission charges and low swap charges combined with our advanced technology make your trading experience exemplary in every sense. We do not impose any restriction on any trading strategy either.`
            },
            point2:{
                title: 'ECN plus Account',
                desc: `For traders with minimum deposit of $10,000 or those in AAA category in Loyalty Program, ECN zero is a reward to long for. This is the best combination possible for any trader- Zero Commission on Forex with raw thin spreads, at no mark up. You can enjoy all of this along with our lightning-fast trade execution.`
            },
            point3:{
                title: 'ECN zulu Account',
                desc: `Become part of the largest social trading community by opening a Zulu Account with us. This account is automatically connected to the Zulu Platform, no further action is required. Hence, you automatically get to enjoy razor thin spreads, low commission, our advanced technology, deep liquidity, and lightning-fast execution, along with direct access to world class trading community.`
            },
            para1:`More flexible trading conditions (increased leverage) may apply in case of clients classified as Professional. Please log in to your account and check eligibility for Professional Client, classification, or write to us at`,
        }
    },
    acttraderpage:{
        title: 'ActTrader',
        para1: 'Want to trade but unsure on learning the advanced trading platforms?',
        para2: ` We have got you covered with our ActTrader trading platform. ActTrader has been the front runner in the online trading industry since 2000, equipped with the best-in class technology. The platform focuses on simplicity, readability, and challenging the existing trading traditions with robust technology such that the user can vividly focus on the trading options. ActTrader has witnessed a multi-fold increase in the trading volume up to $5 trillion per month, substantiating the deep liquidity on the platform.`,
        para3: `Begin your smooth trading journey with ActTrader:`,
        questionnaire: {
            question1:{
                heading:'Steps to download ActTrader Desktop',
                answer:``
            }
        }
    }

}